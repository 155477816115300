import styles from "./styles/referrals.module.css";
import React, { useEffect, useState } from "react";
import useReferralsParallax from "./hooks/useReferralsParallax";
import CrossHair from "../../components/svg/CrossHair";
import Arrow from "../../components/svg/Arrow";
import BelongLogo from "../../components/svg/BelongLogo";
import loadingStyles from "./../../components/button/Loading.module.css";
import { useHistory, useLocation } from "react-router-dom";

const apiRequest = async(url: string, body?: any, method?: string) => {
    const API_URL = window.location.host === 'app.be-long.co' ? 'https://0pxnsgnahj.execute-api.eu-west-2.amazonaws.com/' : 'https://b4aayk6lub.execute-api.eu-west-2.amazonaws.com';
    
    let error, data, res;
    try {
        res = await fetch(`${API_URL}${url}`, {method: method || 'GET', body: body ? JSON.stringify(body): null});
    }
    catch(_) {
        error = 'Error retrieving referral information, please refresh and try again';
    }

    if(!error && res) {
        data = await res.json();

        if(data?.error) {
            error = data?.error;    
        }
    }

    return {
        data,
        error,
    };
}

const useQuery = () => {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReferralsOffers = () => {

    const query = useQuery();
    const referralId = query.get("referralId");
    
    const [working, setWorking] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showAppLink, setShowAppLink] = useState(false);
    const [appStoreUrl, setAppStoreUrl] = useState('');

    const [refererUserName, setRefererUserName] = useState('');
    const [refereeAmount, setRefereeAmount] = useState(0);

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const checkReferralId = async() => {
        setError('');
        setShowForm(false);
        // setShowAppLink(false);
        setRefererUserName('');
        setRefereeAmount(0);

        if(!referralId || String(referralId).length < 4) {
            setError(`Invalid link, referral ID missing`);
            return;
        }

        setWorking(true);

        const { error, data } = await apiRequest(`/belong/offers?referralId=${referralId}`);
        if(error) {
            setError(error);
        }
        else {
            setShowForm(true);
            setRefererUserName(data.refererUserName);
            setRefereeAmount(data.refereeAmount);
        }

        setWorking(false);

    }

    const handleFormSubmit = async(e :any) => {
        e.preventDefault();
        setError('');

        if(!referralId || String(referralId).length < 4) {
            setError(`Referral ID missing`);
            return;
        }

        if(!email || String(email).length < 4 || !String(email).includes('@')) {
            setError(`Email missing or invalid`);
            return;
        }

        setWorking(true);

        const { error, data } = await apiRequest(`/belong/offers`, {email, referralId}, 'POST');

        if(error) {
            setError(error);
        }
        else {
            if(data.appStoreUrl) {
                setAppStoreUrl(data.appStoreUrl);
                setShowForm(false);
                setShowAppLink(true);
            }
        }
        

        setWorking(false);
    }

    const downloadApp = () => {
        window.open(appStoreUrl);
    }

    useEffect(()=>{ checkReferralId(); }, [referralId]);

  return (
    <div className="App" id="app" style={{ display: "flex", flexDirection: "column" }}>
      <div className={styles.logoContainer}><BelongLogo width={150} height={150} /></div>
      <div id="background" className={styles.mainContentContainer}>
        <div className={styles.heroBackground}>
          {!showAppLink && <p className={styles.heroText}>
            Fortune
            <br />
            favours those who Belong
          </p>}
        </div>
        <img
          className={styles.backgroundImage}
          id="backgroundImage"
          src="/background/large.jpg"
          alt="t"
        />
        <div className={styles.refCodeFormContainer}>
        
          <p className={styles.formWelcome}>
            {showAppLink && <>You're in, time to Belong!</>}
            {!showAppLink && <>Enter your email to accept your golden ticket from {refererUserName}</>}
          </p>

          {showForm && <>
          <form className={styles.formContainer} onSubmit={handleFormSubmit}>
                <div className={styles.referralInputContainer}>
                    <input type={"email"} name="email" className={styles.referralInput} placeholder="Your email address" onChange={(e)=>{ setEmail(e.target.value) }} />
                    <button className={styles.referralInputButton} style={{backgroundColor: true ? "#bf9577" : undefined,}}>
                        {working ? (
                            <div className={loadingStyles.ldsRing}>
                                <div></div><div></div><div></div><div></div>
                            </div>
                        ) : (
                            <Arrow />
                        )}
                    </button>
                </div>
            </form>
        </>}
        {/* {showAppLink && <>
            <p className={styles.whiteText}>Download the app and make your first investment to claim your £{refereeAmount} top up.</p>
            <span style={{cursor: 'pointer'}} onClick={downloadApp}><img src="/logos/ios-download-app.png" /></span>
        </>} */}
        {showAppLink && <>
          {refereeAmount > 0 && <p className={styles.whiteText}>Download the app and make your first investment to claim your £{refereeAmount} top up.</p>}
          {refereeAmount === 0 && <p className={styles.whiteText}>Download the app to get started and make your first investment.</p>}
          <div className={styles.buttonContainer}>
              <div className={styles.button} onClick={downloadApp}>Get the app</div>
          </div>
        </>}
        {error && <p className={styles.errorText}>{error}</p>}
        </div>
      </div>
      <div className={styles.disclaimerContainer}>
        <div className={styles.disclaimerCrossHair}>
          <CrossHair width={40} height={40} />
        </div>
        <div>
          <p>
            As with all investing, your money will be at risk. The value of your
            investment may go down as well as up and you could get back less
            than you put in.
          </p>
          <p>
            Avione Saving & Investment Ltd (FRN 971870) (trading as Belong) is
            an appointed representative of RiskSave Technologies, which is
            authorised and regulated by the Financial Conduct Authority (FRN
            775330) Avione Saving & Investment Ltd is a registered company
            number 12873469. 5 New Street Square, London EC4A 3TW
          </p>
        </div>
      </div>
    </div>
  );

}

export { ReferralsOffers };
