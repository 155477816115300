import "./styles.css";

export function Finimize() {
    return (
        <div style={{minHeight: '100vh', background:'url(background/night-clouds.jpg)', }}>
            <div className="finimize-container">

                <h1 className="white">How to get a <br />Belong Boost loan</h1>

                <ol>
                <li>Use code INDEXFUND to skip the waitlist <br /><div align="center"><div className="orange-pill">Skip the waitlist</div></div></li>
                <li>Download the Belong app and make your first investment</li>
                <li>Register your interest for a Boost loan</li>
                <li>Boost loans are released on a first come first served basis - you’ll be notified when it’s your turn to apply!</li>
                </ol>

                <h1 className="white">How does the <br />Boost loan work?</h1>
                <p className="strong">With a Boost loan, you can borrow up to 1x your investment value (between £1k - £20k) to invest in the stock market for the long-term.</p>

                <table width="100%">
                    <tr>
                        <td align="center" className="grey-pill" width={'48%'}><h4>5 yrs</h4><h5>Boost loan term</h5></td>
                        <td></td>
                        <td align="center" className="grey-pill" width={'48%'}><h4>monthly</h4><h5>repayment frequency</h5></td>
                    </tr>
                </table>

                <p></p>

                <div className="brown-pill">
                <table width="100%">
                    <tr>
                        <td align="center"><h4 className="white">6%</h4>fixed interest rate</td>
                        <td align="center"><h4 className="white">+</h4></td>
                        <td align="center"><h4 className="white">2%</h4>set up fee</td>
                        <td align="center"><h4 className="white">=</h4></td>
                        <td align="center"><h4 className="white">6.92%</h4>representative APR</td>
                    </tr>
                </table>
                </div>

                <p></p>

                <p>Boost loan approval is always subject to affordability and eligibility criteria.  If accepted for a Boost Loan, you have the right to withdraw from the Credit Agreement within 14 days of signing the contract without incurring any penalties. Belong is a credit broker, not a lender.</p>
                <p>While a larger starting investment increases your profits when the market goes up, it also increases your losses when the market goes down. The optional Boost loan could increase your losses when it is trading beneath the starting value. This is most likely to happen if there is a market dip early in the life of your Belong investment and you can’t afford
to hold.</p>


            </div>
        </div>
    );
}
